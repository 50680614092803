<template>
    <v-checkbox
        v-model="valueLocal"
        :label="field.titleSingular"
        :rules="field.rules"
        :readonly="isReadonly"
        :dense="isDense"
        :disabled="isDisabled" />
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            default: null,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Object,
            default: null,
        },
        value: {
            type: Boolean,
            default: false,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    mounted() {
        if (!this.value) {
            this.valueLocal = false;
        }
    },
};
</script>
